import { Button, CircularProgress, Divider, Stack, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { Api } from "../../apis";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/setting";

const Index = () => {
    const navigate = useNavigate();
    const { contextStore, setContext } = useContext<any>(Context);
    const [hospitalId, setHospitalId] = useState("");
    const [patientId, setPatientId] = useState("");
    const [loader, setLoader] = useState<boolean>(false);
    const handleClick = () => {
        setLoader(true);
        Api?.chatMed({
            subject_id: Number(patientId),
            hospital_admission_id: Number(hospitalId),
            query: "Can you provide the discharge summary of this patient?",
        })
            ?.then((res: any) => {
                if (res?.status === 202) {
                    navigate("/med_summary", { state: { hospitalId, patientId, task_id: res?.data?.task_id } });
                } else {
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "error",
                            message: "Failed to fetch details, Please try again later!",
                        },
                    });
                }
                setLoader(false);
            })
            ?.catch((err) => {
                setLoader(false);
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Failed to fetch details, Please try again later!",
                    },
                });
            });
    };
    return (
        <Stack alignItems={"center"} flex={1}>
            <Stack
                mt={6}
                py={1}
                alignItems={"center"}
                gap={2}
                border={"1px solid #F5F5F5"}
                borderRadius={"8px"}
                minWidth={"32rem"}
                sx={{ background: "#fff" }}
            >
                <Typography pb={0.5} fontSize={"1.1rem"}>
                    Medical Summarizer
                </Typography>
                <Divider sx={{ borderColor: "#F5F5F5", width: "100%" }} />
                <Typography pt={2} pb={1} sx={{ fontSize: "0.85rem", color: "#424f65" }}>
                    Enter Patient ID to get the information.
                </Typography>

                <TextField
                    value={hospitalId}
                    onChange={(e: { target: { value: string } }) => setHospitalId(() => e?.target?.value)}
                    label="Hospital ID"
                    size="small"
                    sx={{ height: "2rem", mt: 0.3, maxWidth: "10rem", "& *": { fontSize: "0.9rem" } }}
                />
                <TextField
                    value={patientId}
                    onChange={(e: { target: { value: string } }) => setPatientId(() => e?.target?.value)}
                    label="Patient ID"
                    size="small"
                    sx={{ height: "2rem", mt: 0.3, maxWidth: "10rem", "& *": { fontSize: "0.9rem" } }}
                />

                <Button
                    sx={{
                        background: "#007BFF",
                        color: "#fff",
                        height: "2rem",
                        width: "13rem",
                        my: 2.5,
                        "&:hover": {
                            background: "#007BFF",
                            color: "#fff",
                        },
                    }}
                    onClick={handleClick}
                    disabled={hospitalId?.length === 0 || patientId?.length === 0}
                >
                    {loader ? <CircularProgress style={{ width: "25px", height: "25px" }} /> : "Generate Report"}
                </Button>
            </Stack>
        </Stack>
    );
};

export default Index;
