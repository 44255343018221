import { Box, Button, Card, CardActions, CardContent, CardMedia, CircularProgress, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import DamageReports from "./DamageReports";
import { Api } from "../../../apis";
import { Context } from "../../../context/setting";
import moment from "moment";

const ReportsList = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [selectedReport, setSelectedReport] = useState<any>(null);
    const [data, setData] = useState<any>([]);
    const [start, setStart] = useState<number>(1);
    const [totalRecordsCount, setTotalRecordsCount] = useState<number>(2);
    const [loading, setLoading] = useState<boolean>(false);
    const [scrollPosition, setPosition] = useState({ scrollHeight: 0, scrollY: 0, height: 0, scrolledTo: 0 });

    const fetchData = () => {
        if (start < totalRecordsCount && !loading) {
            setLoading(true);
            Api?.getDamageDetectionReports("3ddf28da-ca5e-4615-ab05-50d30c888307", start, 12)?.then((res: any) => {
                if (res?.status === 200) {
                    setData((prev: any) => [...prev, ...res?.data?.[0]]);
                    setTotalRecordsCount(res?.data?.[1]);
                    setStart((prev) => prev + 12);
                } else {
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "error",
                            message: "Server issue! Please try after some time",
                        },
                    });
                }
                setLoading(false);
            });
        } else if (loading) {
            setContext({
                ...contextStore,
                snackState: {
                    open: true,
                    errorType: "success",
                    message: "Fetching your reports. Please wait!!!",
                },
            });
        } else if (start > totalRecordsCount) {
            setContext({
                ...contextStore,
                snackState: {
                    open: true,
                    errorType: "success",
                    message: "All reports fetched successfullyt!!!",
                },
            });
        }
    };

    const calcDamage = (data: any) => {
        let count = 0;
        for (let i = 0; i < data?.image_results?.length; i++) {
            count += data?.image_results?.[i]?.file_damage_counts?.[0]?.["Damage Count"];
        }
        return count;
    };

    useEffect(() => {
        const doc: any = document.getElementById("reports_container");
        function updatePosition() {
            setPosition({
                scrollHeight: doc.scrollHeight,
                scrollY: doc.scrollTop,
                height: doc.offsetHeight,
                scrolledTo: doc.scrollTop + doc.scrollHeight + 300,
            });
        }

        doc.addEventListener("scroll", updatePosition);
        updatePosition();

        return () => window.removeEventListener("scroll", updatePosition);
    }, []);

    useEffect(() => {
        if (scrollPosition.scrolledTo >= scrollPosition.scrollHeight) {
            fetchData();
        }
    }, [scrollPosition]);

    return selectedReport !== null ? (
        <DamageReports
            selectedReport={selectedReport}
            setSelectedReport={setSelectedReport}
            data={data[selectedReport]}
        />
    ) : (
        <Box
            id="reports_container"
            sx={{
                width: "100%",
                height: "100vh",
                display: "flex",
                p: 3,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                overflowY: "scroll",
                gap: 3,
            }}
        >
            <Typography variant="h5" sx={{ color: "#007bff", mt: "2%" }}>
                Damage Reports
            </Typography>
            <Box
                sx={{
                    width: "90%",
                    height: "95vh",
                    pb: 1,
                    display: "flex",
                    flexWrap: "wrap",
                }}
            >
                {data?.map((item: any, index: any) => {
                    return (
                        <Card
                            sx={{
                                width: "24%",
                                height: "45%",
                                minHeight: "300px",
                                borderRadius: "15px",
                                "&:hover": {
                                    transform: "scale(1.03)",
                                },
                                mr: "1%",
                                mb: "3%",
                            }}
                        >
                            <CardMedia
                                sx={{ height: 150 }}
                                image={`data:image/png;base64,${item?.image_results?.[0]?.detailed_reports?.[0]?.[1]?.original_base64}`}
                                title={`${item?.report_number}`}
                            />
                            <CardContent sx={{ height: 110 }}>
                                <Typography gutterBottom variant="h5" component="div">
                                    {`Report: ${index + 1}`}
                                </Typography>
                                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                                    {`Created ${moment(item?.report_number).fromNow()}`}
                                </Typography>
                                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                                    {`Total damages detected: ${calcDamage(item)}`}
                                </Typography>
                                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                                    {`Created by: ${contextStore?.profile?.name}`}
                                </Typography>
                            </CardContent>
                            <CardActions sx={{ height: 60 }}>
                                <Button size="small" onClick={() => setSelectedReport(index)}>
                                    View Now
                                </Button>
                            </CardActions>
                        </Card>
                    );
                })}
                {loading && (
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: "2%",
                            height: "50px",
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default ReportsList;
