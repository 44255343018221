import { Box } from "@mui/material";
import Webcam from "react-webcam";

const WebCam = ({ webcamRef, canvasRef }: any) => {
    return (
        <Box
            sx={{
                zIndex: 1,
                position: "absolute",
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
                overflowY: "clip",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#88888a",
            }}
        >
            <Webcam
                style={{
                    height: "fit-content",
                    width: "100%",
                    // objectFit: "fill",
                    position: "absolute",
                    minHeight: "500px",
                }}
                ref={webcamRef}
                audio={false}
                screenshotFormat="image/jpeg"
                videoConstraints={{ facingMode: { exact: "environment" } }}
            />
            <canvas
                ref={canvasRef}
                style={{
                    position: "absolute",
                    marginLeft: "auto",
                    marginRight: "auto",
                    left: 0,
                    right: 0,
                    textAlign: "center",
                    zIndex: 8,
                    width: "100vw",
                    height: "100vh",
                }}
            />
        </Box>
    );
};

export default WebCam;

// hjhhgfggghffsqqzxrhghdfhjtatj
