import { Box, CircularProgress, Typography } from "@mui/material";
import WebCam from "./Webcam";
import Stencil from "./Stencil";
import InfoAndCaptureButton from "./InfoAndCaptureButton";
import { useContext, useEffect, useRef, useState } from "react";
import { Api } from "../../apis";
import { Context } from "../../context/setting";
import ImageUploadedConfirmationScreen from "./ImageUploadConfimation/ImageUploadedConfirmation";

const CaptureImage = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [imageIndex, setImageIndex] = useState<number>(0);
    const [image, setImage] = useState<any>(null);
    const [imageUploading, setImageUploading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [detectionObject, setDetectionObject] = useState<any>(null);
    const [currentDate, setCurrentDate] = useState<any>(new Date());
    const [taskList, setTaskList] = useState<any>([]);
    const [data, setData] = useState<any>(null);
    // bulky image data
    const [imageData, setImageData] = useState<any[]>([]);

    const videoRef = useRef<any>(null);
    const canvasRef = useRef<any>(null);
    const webcamRef = useRef<any>(null);
    const elementRef = useRef<any>(null);

    const captureImage = (index: number) => {
        if (webcamRef.current) {
            const imgSrc: any = webcamRef.current.getScreenshot();
            setImage(imgSrc);
        }
    };

    const runModel = async () => {
        setInterval(() => {
            detect();
        }, 1000);
    };

    const detect = async () => {
        // Check data is available
        if (
            typeof webcamRef.current !== "undefined" &&
            webcamRef.current !== null &&
            webcamRef.current.video.readyState === 4
        ) {
            // Get Video Properties
            const video = webcamRef.current.video;
            const videoWidth = webcamRef.current.video.videoWidth;
            const videoHeight = webcamRef.current.video.videoHeight;

            // Set video width
            webcamRef.current.video.width = videoWidth;
            webcamRef.current.video.height = videoHeight;
        }
    };

    const formatDate = (str: string) => {
        return str.replaceAll(" ", "_");
    };

    useEffect(() => {
        if (image !== null) {
            Api?.uploadDamageDetectionFilesRTS({
                user_id: "3ddf28da-ca5e-4615-ab05-50d30c888307",
                image_base64: image.split(",")[1],
                filename: "image.jpg",
                report_number: currentDate,
            })?.then((res: any) => {
                if (res?.status === 200) {
                    setTaskList([...taskList, res?.data?.task_id]);
                    setImageIndex(imageIndex < 7 ? (imageIndex + 1) % 8 : imageIndex);
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "success",
                            message: "Image captured successfully!!",
                        },
                    });
                } else {
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "error",
                            message: "Server issue! Please try after some time",
                        },
                    });
                }
                setLoading(false);
            });
        }
    }, [image]);

    useEffect(() => {
        runModel();
    }, []);

    useEffect(() => {
        if (elementRef.current && elementRef.current.requestFullScreen) {
            elementRef.current.requestFullscreen();
        } else if (elementRef.current && elementRef.current.webkitRequestFullscreen) {
            elementRef.current.webkitRequestFullscreen();
        } else if (elementRef.current && elementRef.current.webkitEnterFullscreen) {
            elementRef.current.webkitEnterFullscreen();
        } else if (elementRef.current && elementRef.current.msRequestFullscreen) {
            elementRef.current.msRequestFullscreen();
        }
    }, [elementRef]);

    // const responseChecker = () => {
    //     console.log(data);
    //     return false;
    // };

    // useEffect(() => {
    //     const ev = new EventSource(
    //         `${Api.damage_detection_file_upload_status}/3ddf28da-ca5e-4615-ab05-50d30c888307/${formatDate(currentDate)}`
    //     );
    //     ev.addEventListener("message", (sse: any) => {
    //         setData(JSON.parse(sse?.data));
    //     });
    // }, []);

    useEffect(() => {
        if (taskList.length === 8) {
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
                setImageUploading(true);
            }, 5000);
        }
    }, [taskList]);

    const loadingTask = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setImageUploading(true);
        }, 2000);
    };

    const handleCapture = (index?: number) => {
        if (index) {
            setImageIndex(index);
        } else {
            setImageIndex(0);
        }
        setImageUploading(false);
    };

    return imageUploading ? (
        <ImageUploadedConfirmationScreen imageData={imageData} handleCapture={handleCapture} />
    ) : (
        <Box
            sx={{
                width: "100vw",
                height: "100vh",
            }}
            ref={elementRef}
        >
            {loading && (
                <Box
                    sx={{
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "none",
                        position: "absolute",
                        zIndex: 100,
                    }}
                >
                    <Box
                        sx={{
                            width: "30vw",
                            height: "40vh",
                            display: "flex",
                            flexDirection: "column",
                            background: "#fff",
                            justifyContent: "center",
                            alignItems: "center",
                            opacity: "0.8",
                            borderRadius: "15px",
                            gap: 3,
                            textAlign: "center",
                            p: 2,
                        }}
                    >
                        <Typography>Please wait, We are loading the images.</Typography>
                        <CircularProgress />
                    </Box>
                </Box>
            )}
            <WebCam videoRef={videoRef} canvasRef={canvasRef} webcamRef={webcamRef} />
            <Stencil imageIndex={imageIndex} detectionObj={detectionObject} />
            <InfoAndCaptureButton
                imageIndex={imageIndex}
                setImageIndex={setImageIndex}
                captureImage={captureImage}
                loadingTask={loadingTask}
            />
        </Box>
    );
};

export default CaptureImage;

// ugyufytfotrdtrsrresrejugsgnwrt
