import { Stack, Typography, Button, Select, MenuItem, Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import { Api } from "../../../apis";
import DocViewer from "@cyntler/react-doc-viewer";

const PdfSection = ({ setPdfModal, files }: { setPdfModal: (value: boolean) => void; files: any[] }) => {
    const errorRef = useRef<any>();
    const [selectedFile, setSelectedFile] = useState<string>("Please Select A File");
    const [loading, setLoading] = useState<boolean>(false);
    const [documentURI, setDocumentURI] = useState<any>();
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [sizes, setSizes] = useState({ width: 100, height: 100 });
    const [scale, setScale] = useState(1.0);
    useEffect(() => {
        setSelectedFile(files[0] || "Please Select A File");
        files?.length > 0 && handlePdfBlob(files[0], files[0]?.endsWith("pdf") ? "pdf" : "docx");
    }, [files]);

    const handlePdfBlob = (uri: string, type: "pdf" | "docx") => {
        setLoading(true);
        Api?.getDocument(uri, type)
            ?.then((res: any) => {
                if (res?.status === 200) {
                    console.log(res?.data);
                    if (res?.data?.sas_url) {
                        setDocumentURI({ docx: res?.data?.sas_url });
                    } else {
                        const blob = new Blob([res?.data], { type: "application/pdf" });
                        const url = URL.createObjectURL(blob);
                        setDocumentURI(url);
                    }
                    errorRef.current.textContent = "";
                    errorRef.current.style.color = "inherit";
                } else {
                    errorRef.current.textContent = "Failed to fetch document.";
                    errorRef.current.styles.color = "red";
                    setLoading(false);
                }
            })
            ?.catch((err) => {
                errorRef.current.textContent = "Failed to fetch document.";
                errorRef.current.styles.color = "red";
                setLoading(false);
            });
    };

    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setNumPages(numPages);
    };

    console.log(documentURI);

    return (
        <Stack
            ml={1.56}
            p={1}
            px={2}
            gap={1}
            sx={{
                height: "90.7vh",
                "& .react-pdf__Page": { backgroundColor: "inherit !important" },
                "& .react-pdf__Page__canvas": { height: "fit-content !important", width: "90% !important" },
            }}
        >
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Typography>Reference Documents</Typography>
                <Button sx={{ textTransform: "none", color: "#007BFF" }} onClick={() => setPdfModal(false)}>
                    Close
                </Button>
            </Stack>
            <Stack>
                <Select
                    value={selectedFile}
                    onChange={(e: { target: { value: string } }) => {
                        setSelectedFile(e?.target?.value);
                        handlePdfBlob(e?.target?.value, e?.target?.value?.endsWith("pdf") ? "pdf" : "docx");
                    }}
                    sx={{
                        height: "2rem",
                        borderRadius: "19px",
                        background: "#E3F4FF",
                        "&:first-child": { border: "none", fontSize: "0.8rem", fontFamily: "Gilroy-SemiBold" },
                        "& *": { border: "none", fontSize: "0.8rem", fontFamily: "Gilroy-SemiBold" },
                        "& svg": { fill: "#007BFF" },
                        width: "15rem",
                        color: "#007BFF",
                        textTransform: "none",
                    }}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                borderRadius: "10px",
                                background: "#fbfdff",
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                "& .MuiMenuItem-root": {
                                    fontSize: "0.8rem",
                                    color: "#717171",
                                    fontFamily: "Gilroy-SemiBold",
                                },
                                "& .MuiMenuItem-root.Mui-selected": {
                                    color: "#007BFF",
                                },
                            },
                        },
                    }}
                >
                    {files?.map((item) => <MenuItem value={item}>{item?.split("_")?.join(" ")}</MenuItem>)}
                </Select>
            </Stack>
            <Typography ref={errorRef}></Typography>
            <Stack sx={{ flex: "1 0 10vh", overflowY: "auto", py: 1 }}>
                {typeof documentURI === "string" && (
                    <Document file={documentURI} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page key={`page_${pageNumber}`} pageNumber={pageNumber} />
                    </Document>
                )}
                {documentURI?.docx && (
                    <DocViewer
                        documents={[{ uri: documentURI?.docx, fileType: "docx" }]}
                        config={{
                            header: {
                                overrideComponent: Header,
                            },
                        }}
                        style={{ height: "99%" }}
                    />
                )}
            </Stack>
        </Stack>
    );
};

const Header = () => {
    return <></>;
};

export default PdfSection;
